import { Component, Output, EventEmitter } from '@angular/core';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { Store } from '@ngrx/store';
import * as AuthActions from '../../../../core/authentication/state/actions/auth.actions';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

interface MenuItem {
	label: string;
	link?: string;
	icon?: string;
	subMenu?: MenuItem[];
}

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	standalone: true,
	imports: [NgClass, NgForOf, NgIf, RouterLink, TranslateModule],
	styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
	isMenuOpen = false;
	activeSubmenu: string | null = null;
	@Output() menuClick = new EventEmitter<void>();

	menuItems: MenuItem[] = [
		{ label: 'HEADER.HOME', link: '/home' },
		{ label: 'HEADER.MY_ACCOUNT', link: '/account' },
		{
			label: 'HEADER.TRANSFERS',
			subMenu: [
				{
					label: 'HEADER.NEW_TRANSFER',
					link: '/transfer',
					subMenu: [
						{
							label: 'HEADER.INTERNAL_TRANSFER',
							link: '/transfer/new/internal',
						},
						{
							label: 'HEADER.EXTERNAL_TRANSFER',
							link: '/transfer/new/external',
						},
					],
				},
				{
					label: 'HEADER.TRANSFER_REQUEST',
					subMenu: [
						{
							label: 'HEADER.HISTTORY',
							link: '/transfer/request/history',
						},
						{
							label: 'HEADER.PENDING',
							link: '/transfer/request/in-pending',
						},
					],
				},
				{
					label: 'HEADER.MASS_TRANSFER',
					subMenu: [
						{
							label: 'HEADER.MASS_FORM',
							link: '/transfer/mass',
						},
						{
							label: 'HEADER.MASS_FILE',
							link: '/transfer/mass/file-example',
						},
					],
				},
				{
					label: 'HEADER.PLANNED_TRANSFERS',
					link : '/transfer/planned'
				},
				{
					label: 'HEADER.BENEFICIARY_MANAGEMENT',
					subMenu: [
						{
							label: 'HEADER.LIST_OF_BENEFICIARIES',
							link: '/beneficiary',
						},
						{
							label: 'HEADER.CREATE_A_BENEFICIARY',
							link: '/beneficiary/new',
						},
						{
							label: 'HEADER.ACTIVATE_A_BENEFICIARY',
							link: '/beneficiary/validate',
						},
					],
				},
				{
					label: 'HEADER.BILL_PAYMENT',
					subMenu: [
						{
							label: 'HEADER.INITIATE_PAYMENT',
							link: '/transfer/bill-payment',
						},
						{
							label: 'HEADER.APPROVE_PENDING_PAYMENT',
							link: '',
						},
						{
							label: 'HEADER.PAYMENT_HISTORY',
							link: '',
						},
						{
							label: 'HEADER.INVOICE_MANAGEMENT',
							link: '',
						},
					],
				},
				{
					label: 'HEADER.HISTORY',
					link: "/transfer/history"
				},
			],
		},
		{
			label: 'HEADER.BANK_WALLET',
			subMenu: [
				{ label: 'HEADER.WALLET_TO_BANK', link: '/wallet-to-bank' },
				{ label: 'HEADER.BANK_TO_WALLET', link: '/bank-to-wallet' },
			],
		},
		{ label: 'HEADER.BENEFICIARY', link: '/beneficiary' },
	];

	rightIcons: MenuItem[] = [
		{ label: 'Menu', icon: 'menu', link: '' },
		{ label: 'Profil', icon: 'user', link: '/settings' },
		{ label: 'Paramètres', icon: 'settings', link: '/settings' },
		{ label: 'Messages', icon: 'message', link: '/message' },
		{ label: 'Notifications', icon: 'bell', link: '/message' },
		{ label: 'Déconnexion', icon: 'logout', link: '' },
	];

	constructor(private store: Store) {}

	toggleMenu() {
		this.isMenuOpen = !this.isMenuOpen;
		this.activeSubmenu = null;
	}

	toggleSubmenu(label: string) {
		this.activeSubmenu = this.activeSubmenu === label ? null : label;
	}

	onIconClick(iconLabel: string) {
		if (iconLabel === 'Menu') {
			this.menuClick.emit();
		} else if (iconLabel === 'Déconnexion') {
			this.logout();
		}
	}
	logout() {
		this.store.dispatch(AuthActions.logout());
	}
}
